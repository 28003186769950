// src/pages/Users.js
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { Modal, Button, Form } from 'react-bootstrap';
import './Users.css'; // Import your custom CSS

function Users() {
  const { auth } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  // States for Add/Edit Modal
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('Add'); // 'Add' or 'Edit'
  const [currentUser, setCurrentUser] = useState(null);

  // Form Fields
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [recoveryEmail, setRecoveryEmail] = useState(''); // New state for recoveryEmail
  const [mobile, setMobile] = useState(''); // New state for mobile
  const [role, setRole] = useState('user');
  const [password, setPassword] = useState('');

  // Fetch users on component mount
  useEffect(() => {
    fetchUsers();
  }, [auth.token]);

  const fetchUsers = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get('/api/users', {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setUsers(response.data.data);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to fetch users');
    } finally {
      setLoading(false);
    }
  };

  // Handle Show Modal
  const handleShowModal = (type, user = null) => {
    setModalType(type);
    if (type === 'Edit') {
      setCurrentUser(user);
      setName(user.name);
      setEmail(user.email);
      setRecoveryEmail(user.recoveryEmail || ''); // Populate recoveryEmail if available
      setMobile(user.mobile || ''); // Populate mobile if available
      setRole(user.role);
      // Password is left empty for security reasons
      setPassword('');
    } else {
      // Reset form fields
      setCurrentUser(null);
      setName('');
      setEmail('');
      setRecoveryEmail('');
      setMobile('');
      setRole('user');
      setPassword('');
    }
    setShowModal(true);
  };

  // Handle Close Modal
  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentUser(null);
    setName('');
    setEmail('');
    setRecoveryEmail('');
    setMobile('');
    setRole('user');
    setPassword('');
  };

  // Handle Add User
  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      const payload = { name, email, recoveryEmail, mobile, role, password };
      const response = await axios.post('/api/users', payload, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setUsers([...users, response.data.data]);
      handleCloseModal();
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to add user');
    }
  };

  // Handle Edit User
  const handleEditUser = async (e) => {
    e.preventDefault();
    try {
      const payload = { name, email, recoveryEmail, mobile, role };
      if (password.trim() !== '') {
        payload.password = password;
      }
      const response = await axios.put(`/api/users/${currentUser._id}`, payload, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setUsers(
        users.map((user) =>
          user.id === currentUser.id ? response.data.data : user
        )
      );
      handleCloseModal();
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to update user');
    }
  };

  // Handle Delete User
  const handleDeleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await axios.delete(`/api/users/${userId}`, {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        setUsers(users.filter((user) => user._id !== userId));
      } catch (err) {
        setError(err.response?.data?.message || 'Failed to delete user');
      }
    }
  };

  return (
    <div className="users-page container">
      <h2 className="mt-4">Users</h2>

      {/* Add User Button */}
      <Button
        variant="primary"
        className="mb-3"
        onClick={() => handleShowModal('Add')}
      >
        Add User
      </Button>

      {/* Error Message */}
      {error && <div className="alert alert-danger">{error}</div>}

      {/* Users Table */}
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Recovery Email</th>
            <th>Mobile</th>
            <th>Role</th>
            <th>Company</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="7" className="text-center">
                Loading users...
              </td>
            </tr>
          ) : users.length > 0 ? (
            users.map((user) => (
              <tr key={user.id}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.recoveryEmail || '—'}</td>
                <td>{user.mobile || '—'}</td>
                <td>{user.role}</td>
                <td>{user.company.name}</td> {/* Assuming company has a name field */}
                <td>
                  <Button
                    variant="warning"
                    size="sm"
                    className="me-2"
                    onClick={() => handleShowModal('Edit', user)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDeleteUser(user._id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="text-center">
                No Users Found.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Add/Edit User Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalType} User</Modal.Title>
        </Modal.Header>
        <Form onSubmit={modalType === 'Add' ? handleAddUser : handleEditUser}>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formUserName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter full name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formUserEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            {/* New Field: Recovery Email */}
            <Form.Group className="mb-3" controlId="formUserRecoveryEmail">
              <Form.Label>Recovery Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter recovery email"
                value={recoveryEmail}
                onChange={(e) => setRecoveryEmail(e.target.value)}
                required // Ensure it's required as per backend
              />
            </Form.Group>

            {/* New Field: Mobile */}
            <Form.Group className="mb-3" controlId="formUserMobile">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter mobile number"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                required // Ensure it's required as per backend
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formUserRole">
              <Form.Label>Role</Form.Label>
              <Form.Select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                required
              >
                <option value="admin">Admin</option>
                <option value="manager">Manager</option>
                <option value="user">User</option>
              </Form.Select>
            </Form.Group>

            {/* Password Field */}
            <Form.Group className="mb-3" controlId="formUserPassword">
              <Form.Label>
                Password{' '}
                {modalType === 'Edit' && (
                  <span className="text-muted">(Leave blank to keep current password)</span>
                )}
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                minLength="6"
                required={modalType === 'Add'} // Required only for Add
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {modalType === 'Add' ? 'Add User' : 'Save Changes'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}

export default Users;
