// src/pages/Settings.js
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import './Settings.css'; // Your existing CSS
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Form } from 'react-bootstrap'; // Importing React Bootstrap components

function Settings() {
  const { auth } = useContext(AuthContext);
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [newKeyword, setNewKeyword] = useState('');
  const [newSubstring, setNewSubstring] = useState(''); 
  const [includeCredits, setIncludeCredits] = useState(true);
  const [includeDebits, setIncludeDebits] = useState(true);
  const [negativeSubstrings, setNegativeSubstrings] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [currentSubstring, setCurrentSubstring] = useState('');
  const [updatedSubstring, setUpdatedSubstring] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [substringToDelete, setSubstringToDelete] = useState('');

  // Define fetchTransactions outside useEffect for reusability
  const fetchTransactions = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get('/api/transactions/getTransactionsSettingPage', {
        headers: { Authorization: `Bearer ${auth.token}` },
        params: { page: 1, limit: 1000, includeExcluded: true, sort: 'date' }, // Fetch all transactions including excluded ones
      });
      setTransactions(response.data.data);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to fetch transactions');
      toast.error(err.response?.data?.message || 'Failed to fetch transactions');
    } finally {
      setLoading(false);
    }
  };

  // Define fetchNegativeSubstrings
  const fetchNegativeSubstrings = async () => {
    try {
      const response = await axios.get('/api/settings/substrings', {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setNegativeSubstrings(response.data.negativeSubstrings);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to fetch negative substrings');
      toast.error(err.response?.data?.message || 'Failed to fetch negative substrings');
    }
  };

  // Fetch all transactions and negative substrings on component mount
  useEffect(() => {
    fetchTransactions();
    fetchNegativeSubstrings();
  }, [auth.token]);

  // Function to toggle inclusion of credits
  const toggleIncludeCredits = async () => {
    try {
      const response = await axios.patch(
        '/api/transactions/includeCredits',
        { include: !includeCredits },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );

      setIncludeCredits(response.data.includeCredits);
      toast.success(response.data.message);

      // Re-fetch transactions to reflect changes
      fetchTransactions();
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to update Include Credits');
      toast.error(err.response?.data?.message || 'Failed to update Include Credits');
    }
  };

  // Function to toggle inclusion of debits
  const toggleIncludeDebits = async () => {
    try {
      const response = await axios.patch(
        '/api/transactions/includeDebits',
        { include: !includeDebits },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );

      setIncludeDebits(response.data.includeDebits);
      toast.success(response.data.message);

      // Re-fetch transactions to reflect changes
      fetchTransactions();
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to update Include Debits');
      toast.error(err.response?.data?.message || 'Failed to update Include Debits');
    }
  };

  // Function to toggle exclusion status
  const toggleExclusion = async (transactionId, currentStatus) => {
    try {
      const response = await axios.patch(
        `/api/transactions/${transactionId}`,
        { isExcluded: !currentStatus },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );

      // Debugging: Log the updated transaction
      console.log('Updated Transaction:', response.data.transaction);

      // Update the local state
      setTransactions((prevTransactions) =>
        prevTransactions.map((tx) =>
          tx.id === transactionId ? response.data.transaction : tx
        )
      );

      // Show success notification
      toast.success(
        `Transaction ${!currentStatus ? 'excluded' : 'included'} successfully!`
      );
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to update transaction');
      toast.error(err.response?.data?.message || 'Failed to update transaction');
    }
  };

  // Handler to initiate the consent flow
  const handleConsent = async () => {
    try {
      const response = await axios.get('/api/consent/consent-url', {
        headers: { Authorization: `Bearer ${auth.token}` },
      });

      const { consentUrl } = response.data;

      // Open the consent URL in a new tab/window
      window.open(consentUrl, '_blank');
      toast.info('Consent process initiated. Please complete it in the new tab.');
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to initiate consent');  
      toast.error(err.response?.data?.message || 'Failed to initiate consent');
    }
  };


   // Handlers for adding negative keywords and substrings
  const handleAddKeyword = async () => {
    if (newKeyword.trim() === '') {
      toast.warning('Please enter a valid keyword.');
      return;
    }
    try {
      // Assume there's an API endpoint to add negative keywords
      const response = await axios.post(
        '/api/settings/keywords',
        { keyword: newKeyword },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      setNewKeyword('');
      toast.success('Negative keyword added successfully!');
      // Re-fetch transactions to apply new keyword filters
      fetchTransactions();
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to add negative keyword');
      toast.error(err.response?.data?.message || 'Failed to add negative keyword');
    }
  };

  const handleAddSubstring = async () => {
    if (newSubstring.trim() === '') {
      toast.warning('Please enter a valid substring.');
      return;
    }
    try {
      // Assume there's an API endpoint to add negative substrings
      const response = await axios.post(
        '/api/settings/substrings',
        { substring: newSubstring },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      setNewSubstring('');
      toast.success("Negative 'substring' added successfully!");
      // Re-fetch transactions to apply new substring filters
      fetchTransactions();
      // Re-fetch negative substrings to update the list
      fetchNegativeSubstrings();
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to add negative substring');
      toast.error(err.response?.data?.message || 'Failed to add negative substring');
    }
  };

  // Function to determine if a transaction is a credit or debit
  const isCredit = (tx) => tx.amount > 0;
  const isDebit = (tx) => tx.amount < 0;

  // Filter transactions based on Payment Filters
  const filteredTransactions = transactions.filter((tx) => { 
    if (isCredit(tx) && !includeCredits) return true;
    if (isDebit(tx) && !includeDebits) return true;
    return true;
  });

  // Handlers for Update Modal
  const handleShowUpdateModal = (substring) => {
    setCurrentSubstring(substring);
    setUpdatedSubstring(substring);
    setShowUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    setCurrentSubstring('');
    setUpdatedSubstring('');
  };

  const handleUpdateSubstring = async () => {
    if (updatedSubstring.trim() === '') {
      toast.warning('Please enter a valid substring.');
      return;
    }
    try {
      const response = await axios.put(
        `/api/settings/substrings/${encodeURIComponent(currentSubstring)}`,
        { newSubstring: updatedSubstring },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      toast.success('Negative substring updated successfully!');
      handleCloseUpdateModal();
      // Re-fetch substrings and transactions
      fetchNegativeSubstrings();
      fetchTransactions();
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to update substring');
      toast.error(err.response?.data?.message || 'Failed to update substring');
    }
  };

  // Handlers for Delete Modal
  const handleShowDeleteModal = (substring) => {
    setSubstringToDelete(substring);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSubstringToDelete('');
  };

  const handleDeleteSubstring = async () => {
    try {
      const response = await axios.delete(
        `/api/settings/substrings/${encodeURIComponent(substringToDelete)}`,
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      toast.success('Negative substring deleted successfully!');
      handleCloseDeleteModal();
      // Re-fetch substrings and transactions
      fetchNegativeSubstrings();
      fetchTransactions();
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to delete substring');
      toast.error(err.response?.data?.message || 'Failed to delete substring');
    }
  };

  return (
    <div className="container mt-4">
      <div className="row">
        {/* Left Column: Payment Filters */}
        <div className="col-md-4"> 
          <h5>Payment Filters</h5>
          <div
            className="form-check keyword-box"
            style={{
              borderRadius: '5px',
              backgroundColor: 'white',
              padding: '10px',
              width: '100%',
              marginBottom: '10px',
            }}
          >
            <label className="form-check-label" htmlFor="includeCredits">
              Include Credits
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="includeCredits"
              name="includeCredits"
              checked={includeCredits}
              onChange={toggleIncludeCredits}
              style={{ float: 'right' }}
            />
          </div>
          <div
            className="form-check keyword-box"
            style={{
              borderRadius: '5px',
              backgroundColor: 'white',
              padding: '10px',
              width: '100%',
              marginBottom: '10px',
            }}
          >
            <label className="form-check-label" htmlFor="includeDebits">
              Include Debits
            </label> 
            <input
              className="form-check-input"
              type="checkbox"
              id="includeDebits"
              name="includeDebits"
              checked={includeDebits}
              onChange={toggleIncludeDebits}
              style={{ float: 'right' }}
            />
          </div>

          {/* Consent Button */}
          <button
            className="btn btn-primary mt-3"
            onClick={handleConsent}
          >
            Consent to Fetch Transactions
          </button>
        </div>

        {/* Right Column: Negative Keywords and Substrings */}
        <div className="col-md-8">
          <h5>All statement lines will appear unless excluded</h5>
          
          {/* Adding Negative Substrings */}
          <div style={{ padding: '10px' }}>
            <label className="col-md-4" style={{ marginTop: '5px' }}>
              Enter Negative 'keyword' OR 'substring'
            </label>
            <input
              style={{ border: '1px solid #ced4da', padding: '5px', borderRadius: '5px' }}
              type="text"
              className="col-md-5"
              placeholder="Enter Negative 'substring'"
              value={newSubstring}
              onChange={(e) => setNewSubstring(e.target.value)}
            />
            <button
              style={{ marginLeft: '10px' }}
              className="col-2 btn btn-secondary btn-sm"
              onClick={handleAddSubstring}
            >
              Add
            </button>
          </div>

          {/* Listing Negative Substrings */}
          <div style={{ padding: '10px' }}>
            <h6>Negative Substrings</h6>
            {negativeSubstrings.length > 0 ? (
              <ul className="list-group">
                {negativeSubstrings.map((substring, index) => (
                  <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                    {substring}
                    <div>
                      <Button variant="warning" size="sm" onClick={() => handleShowUpdateModal(substring)}>
                        Update
                      </Button>{' '}
                      <Button variant="danger" size="sm" onClick={() => handleShowDeleteModal(substring)}>
                        Delete
                      </Button>{' '}
                      {/* <Button variant="info" size="sm" onClick={async () => {
                        // Fetch transactions excluded by this substring
                        try {
                          const response = await axios.get(`/api/settings/substrings/${encodeURIComponent(substring)}/excluded-transactions`, {
                            headers: { Authorization: `Bearer ${auth.token}` },
                          });
                          if (response.data.excludedTransactions.length > 0) {
                            toast.info(`Transactions excluded by "${substring}" have been logged in the console.`);
                            console.log(`Excluded Transactions by "${substring}":`, response.data.excludedTransactions);
                          } else {
                            toast.info(`No transactions are currently excluded by "${substring}".`);
                          }
                        } catch (err) {
                          setError(err.response?.data?.message || 'Failed to fetch excluded transactions');
                          toast.error(err.response?.data?.message || 'Failed to fetch excluded transactions');
                        }
                      }}>
                        View Excluded Transactions
                      </Button> */}
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No negative substrings applied.</p>
            )}
          </div>
        </div> 
      </div>

      {/* Transactions Table */}
      <table className="table table-hover mt-4">
        <thead>
          <tr>
            <th>Date</th>
            <th>Payee</th>
            <th>Reference</th>
            <th>Value</th>
            <th>Include</th>
            <th>Exclude</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="6" className="text-center">
                Loading transactions...
              </td>
            </tr>
          ) : error ? (
            <tr>
              <td colSpan="6" className="text-center text-danger">
                {error}
              </td>
            </tr>
          ) : filteredTransactions.length > 0 ? (
            filteredTransactions.map((tx) => (
              <tr key={tx.id} className={tx.isExcluded ? 'table-secondary' : ''}>
                <td>{new Date(tx.date).toLocaleDateString()}</td>
                <td>{tx.description}</td>
                <td>{tx.reference || '—'}</td>
                <td>£{tx.amount.toFixed(2)}</td>
                <td>
                  {tx.isExcluded ? (
                    <button
                      className="btn btn-success btn-sm"
                      onClick={() => toggleExclusion(tx.id, tx.isExcluded)}
                    >
                      Include
                    </button>
                  ) : (
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => toggleExclusion(tx.id, tx.isExcluded)}
                    >
                      Exclude
                    </button>
                  )}
                </td>
                <td>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={tx.isExcluded}
                    onChange={() => toggleExclusion(tx.id, tx.isExcluded)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                No Transactions Found.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <p className="table-caption">Toggle exclusion to manage transaction visibility on the Transactions page.</p>

      {/* Toast Container for Notifications */}
      <ToastContainer />

      {/* Update Substring Modal */}
      <Modal show={showUpdateModal} onHide={handleCloseUpdateModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Negative Substring</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formUpdatedSubstring">
              <Form.Label>New Substring</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter new substring"
                value={updatedSubstring}
                onChange={(e) => setUpdatedSubstring(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUpdateModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUpdateSubstring}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Substring Modal */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Negative Substring</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the substring "{substringToDelete}"?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteSubstring}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Settings;
