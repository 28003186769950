// src/components/Header.js
import React from 'react';

function Header() {
  return (
    <div className="header-logo">
      <img src="./logo.png" alt="McGowan Rutherford" />
      <hr />
    </div>
  );
}

export default Header;
