// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Login from './pages/Login';
import Users from './pages/Users';
import Transactions from './pages/Transactions';
import Settings from './pages/Settings';
import Profile from './pages/Profile';
import ProtectedRoute from './components/ProtectedRoute';
import Logout from './pages/Logout'; // New Logout component
import { AuthProvider } from './context/AuthContext';
import Unauthorized from './pages/Unauthorized'; // New Unauthorized component
import ReturnPage from './pages/ReturnPage';
import CancelPage from './pages/CancelPage';

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppLayout />
      </Router>
    </AuthProvider>
  );
}

function AppLayout() {
  const location = useLocation();
  
  // Determine if Sidebar should be hidden
  const hideSidebar = location.pathname === '/login' || location.pathname === '/unauthorized' || location.pathname === '/';


  return (
    <>
      {/* Header is always visible */}
      <Header />
      
      <div className="container-fluid">
        <div className="row">
          
          {/* Sidebar is hidden on Login page */}
          {!hideSidebar && <Sidebar />}
          
          {/* Main Content Area */}
          <div className={hideSidebar ? "col-md-12 p-4" : "col-md-10 p-4"}>
            <Routes>
              {/* Public Route: Login */}
              <Route path="/login" element={<Login />} />
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route
                path="/logout"
                element={
                  <ProtectedRoute>
                    <Logout />
                  </ProtectedRoute>
                }
              />

              {/* Protected Routes */}
              <Route
                path="/users"
                element={
                  <ProtectedRoute allowedRoles={['admin', 'manager']}>
                    <Users />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/transactions"
                element={
                  <ProtectedRoute>
                    <Transactions />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <ProtectedRoute allowedRoles={['admin']}>
                    <Settings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />

              {/* Return from External System */}
              <Route path="/settings/return" element={<ReturnPage />} />

              {/* Cancel from External System */}
              <Route path="/settings/cancel" element={<CancelPage />} />
              
              {/* Catch-All Route: Redirect to Login */}
              <Route path="*" element={<Login />} />
            </Routes>
          </div>
        </div>
      </div>
      
      {/* Footer is always visible */}
      <Footer />
      
      {/* 
        Optionally, hide Footer on Login page as well.
        Uncomment the line below and comment out the always-visible Footer above.
      */}
      {/* {!hideSidebar && <Footer />} */}
    </>
  );
}

export default App;
