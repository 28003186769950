// src/pages/Profile.js
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { Tooltip } from 'bootstrap';
import './Profile.css'; // Your existing CSS

function Profile() {
  const { auth, setAuth } = useContext(AuthContext);
  const [profile, setProfile] = useState({
    company: '',
    name: '',
    password: '',
    confirmPassword: '',
    twoFactor: '',
    recoveryEmail: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Fetch profile on component mount
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get('/api/profile', {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        setProfile(response.data);
      } catch (err) {
        setError(err.response?.data?.message || 'Failed to fetch profile');
      }
    };

    fetchProfile();
  }, [auth.token]);

  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (profile.password !== profile.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      await axios.put(
        '/api/profile',
        {
          company: profile.company,
          name: profile.name,
          password: profile.password,
          twoFactor: profile.twoFactor,
          recoveryEmail: profile.recoveryEmail,
        },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      setSuccess('Profile updated successfully');
      setError('');
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to update profile');
      setSuccess('');
    }
  };

  const togglePassword = (e) => {
    const input = e.target.previousElementSibling;
    if (input.type === 'password') {
      input.type = 'text';
      e.target.textContent = '🙈';
    } else {
      input.type = 'password';
      e.target.textContent = '👁';
    }
  };

  // Initialize Bootstrap tooltips
  useEffect(() => {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="company" className="form-label">
            <h5>Company</h5>
          </label>
          <input type="text" id="company" className="form-control" value={profile.company} onChange={handleChange} required />
        </div>

        <hr />

        <div className="mb-4">
          <label htmlFor="name" className="form-label">
            <h5>
              Name{' '}
              <span data-bs-toggle="tooltip" data-bs-placement="top" title="Your full name as it appears on official documents.">
                (?)
              </span>
            </h5>
          </label>
          <input type="text" id="name" className="form-control" value={profile.name} onChange={handleChange} required />
        </div>

        <hr />

        <div className="mb-4">
          <label htmlFor="password" className="form-label">
            <h5>Password</h5>
          </label>
          <div className="password-container">
            <input type="password" id="password" className="form-control" placeholder="Enter your password" value={profile.password} onChange={handleChange} required />
           
          </div>
        </div>

        <div className="mb-4">
          <label htmlFor="confirmPassword" className="form-label">
            <h5>Confirm Password</h5>
          </label>
          <div className="password-container">
            <input type="password" id="confirmPassword" className="form-control" placeholder="Re-enter your password" value={profile.confirmPassword} onChange={handleChange} required />
       
          </div>
        </div>

        <hr />

        <div className="mb-4">
          <label htmlFor="twoFactor" className="form-label">
            <h5>Two-Factor Authentication</h5>
          </label>
          <input type="text" id="twoFactor" className="form-control" placeholder="(+44) 00000000" value={profile.twoFactor} onChange={handleChange} />
        </div>

        <hr />

        <div className="mb-4">
          <label htmlFor="recoveryEmail" className="form-label">
            <h5>Recovery Email</h5>
          </label>
          <input type="email" id="recoveryEmail" className="form-control" value={profile.recoveryEmail} onChange={handleChange} required />
        </div>

        <hr />

        {/* Submit Button */}
        <div className="d-flex justify-content-end">
          <button type="submit" className="btn btn-primary">
            Save Changes
          </button>
        </div>

        {error && <p style={{ color: 'red' }}>{error}</p>}
        {success && <p style={{ color: 'green' }}>{success}</p>}
      </form>
    </div>
  );
}

export default Profile;
