// src/pages/Transactions.js
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import './Transactions.css'; // Import the CSS file

function Transactions() {
  const { auth } = useContext(AuthContext);
  const [transactions, setTransactions] = useState([]);
  const [categorizedTransactions, setCategorizedTransactions] = useState({
    Today: [],
    Yesterday: [],
    Others: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
  const [openDetails, setOpenDetails] = useState({});
  const [activeTab, setActiveTab] = useState('Today'); // New state for active tab

  // Handle window resize for responsive design
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch included transactions on component mount
  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      setError('');
      try {
        const response = await axios.get('/api/transactions', {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
          params: {
            page: 1,
            limit: 1000, // Adjust based on your needs or implement pagination
            sort: 'date',
            order: 'desc',
            includeExcluded: false, // Only include transactions that are not excluded
          },
        });
        setTransactions(response.data.data);
      } catch (err) {
        console.error('Error fetching transactions:', err);
        setError(err.response?.data?.message || 'Failed to fetch transactions');
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [auth.token]);

  // Categorize transactions into Today, Yesterday, and Others
  useEffect(() => {
    const categorizeTransactions = () => {
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);

      const formatDate = (date) => date.toISOString().split('T')[0];

      const categories = {
        Today: [],
        Yesterday: [],
        Others: [],
      };

      transactions.forEach((tx) => {
        const txDate = new Date(tx.date);
        const txDateStr = formatDate(txDate);
        const todayStr = formatDate(today);
        const yesterdayStr = formatDate(yesterday);

        if (txDateStr === todayStr) {
          categories.Today.push(tx);
        } else if (txDateStr === yesterdayStr) {
          categories.Yesterday.push(tx);
        } else {
          // Format the date as DD/MM/YY
          const day = String(txDate.getDate()).padStart(2, '0');
          const month = String(txDate.getMonth() + 1).padStart(2, '0');
          const year = String(txDate.getFullYear()).slice(-2);
          const formattedDate = `${day}/${month}/${year}`;
          if (!categories.Others.find((cat) => cat.dateLabel === formattedDate)) {
            categories.Others.push({ dateLabel: formattedDate, transactions: [] });
          }
          const category = categories.Others.find((cat) => cat.dateLabel === formattedDate);
          category.transactions.push(tx);
        }
      });

      setCategorizedTransactions(categories);
    };

    categorizeTransactions();
  }, [transactions]);

  // Function to toggle transaction details
  const toggleDetails = (transactionId) => {
    setOpenDetails((prev) => ({
      ...prev,
      [transactionId]: !prev[transactionId],
    }));
  };

  // Function to handle tab change
  const handleTabClick = (category) => {
    setActiveTab(category);
  };

  return (
    <div className="transactions-page"> 

      {/* Desktop View */}
      {isDesktop && (
        <div className="desktop-view container">
          {/* Tabs */}
          <ul className="nav nav-tabs" id="transactionTabs" role="tablist">
            {Object.keys(categorizedTransactions).map((category) => (
              <li className="nav-item" role="presentation" key={category}>
                <button
                  className={`nav-link ${activeTab === category ? 'active' : ''}`}
                  id={`${category.toLowerCase()}-tab`}
                  onClick={() => handleTabClick(category)}
                  type="button"
                  role="tab"
                  aria-controls={category.toLowerCase()}
                  aria-selected={activeTab === category ? 'true' : 'false'}
                >
                  {category}
                </button>
              </li>
            ))}
          </ul>

          {/* Tab Content */}
          <div className="tab-content" id="transactionTabsContent">
            {Object.entries(categorizedTransactions).map(([category, txs]) => (
              <div
                className={`tab-pane fade ${activeTab === category ? 'show active' : ''}`}
                id={category.toLowerCase()}
                role="tabpanel"
                aria-labelledby={`${category.toLowerCase()}-tab`}
                key={category}
              >
                {category === 'Others' ? (
                  categorizedTransactions.Others.map((group) => (
                    <div key={group.dateLabel}>
                      <h5>{group.dateLabel}</h5>
                      {group.transactions.length > 0 ? (
                        group.transactions.map((tx) => (
                          <React.Fragment key={tx.id}>
                            <div
                              className="transaction-item"
                              onClick={() => toggleDetails(tx.id)}
                            >
                              <span>{tx.description}</span>
                              <span className="arrow-icon">
                                {openDetails[tx.id] ? '\u2228' /* Down arrow */ : '\u203A' /* Right arrow */}
                              </span>
                            </div>
                            <div
                              className={`transaction-details ${openDetails[tx.id] ? 'active' : ''}`}
                            >
                              <p>£{tx.amount}</p>
                              <p>{new Date(tx.bookingDateTime).toLocaleString()}</p>
                              <p>Ref: {tx.reference || '—'}</p>
                            </div>
                          </React.Fragment>
                        ))
                      ) : (
                        <div className="no-transactions">No Transactions</div>
                      )}
                      <hr />
                    </div>
                  ))
                ) : txs.length > 0 ? (
                  txs.map((tx) => (
                    <React.Fragment key={tx.id}>
                      <div
                        className="transaction-item"
                        onClick={() => toggleDetails(tx.id)}
                      >
                        <span>{tx.description}</span>
                        <span className="arrow-icon">
                          {openDetails[tx.id] ? '\u2228' /* Down arrow */ : '\u203A' /* Right arrow */}
                        </span>
                      </div>
                      <div
                        className={`transaction-details ${openDetails[tx.id] ? 'active' : ''}`}
                      >
                        <p>£{tx.amount}</p>
                        <p>{new Date(tx.bookingDateTime).toLocaleString()}</p>
                        <p>Ref: {tx.reference || '—'}</p>
                      </div>
                    </React.Fragment>
                  ))
                ) : (
                  <div className="no-transactions">No Transactions</div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Mobile View */}
      {!isDesktop && (
        <div className="mobile-view container">
          {/* Iterate over categories */}
          {Object.entries(categorizedTransactions).map(([category, txs]) => (
            <div key={category}>
              <h5>{category}</h5>
              <div className="transaction-section">
                {txs.length > 0 ? (
                  txs.map((tx) => (
                    <React.Fragment key={tx.id}>
                      <div
                        className="transaction-item"
                        onClick={() => toggleDetails(tx.id)}
                      >
                        <span>{tx.description}</span>
                        <span className="arrow-icon">
                          {openDetails[tx.id] ? '\u2228' /* Down arrow */ : '\u203A' /* Right arrow */}
                        </span>
                      </div>
                      <div
                        className={`transaction-details ${openDetails[tx.id] ? 'active' : ''}`}
                      >
                        <p>£{tx.amount}</p>
                        <p>{new Date(tx.bookingDateTime).toLocaleString()}</p>
                        <p>Ref: {tx.reference || '—'}</p>
                      </div>
                    </React.Fragment>
                  ))
                ) : (
                  <div className="no-transactions">No Transactions</div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}


      {/* Display loading and error messages above footer */}
      {loading && <div className="loading-overlay"><p>Loading transactions...</p></div>}
      {error && <div className="error-overlay"><p className="text-danger">{error}</p></div>}
    </div>
  );
}

export default Transactions;
