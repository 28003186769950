// src/pages/Logout.js
import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

function Logout() {
  const navigate = useNavigate();
  const { setAuth } = useContext(AuthContext);

  useEffect(() => {
    // Remove token and user from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('user');

    // Reset auth context
    setAuth({ token: null, user: null });

    // Redirect to login page
    navigate('/login');
  }, [navigate, setAuth]);

  return (
    <div className="logout-page">
      <p>Logging out...</p>
    </div>
  );
}

export default Logout;
